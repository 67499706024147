import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby-plugin-intl";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";

const Beach = ({ image, title, text, waveType, spotLevel, linkTo, linkText, s }) => {
	const [open, setOpen] = useState(false);

	return (
		<div className={`${styles.beach} ${open ? `${styles.active}` : ""}`} onClick={() => setOpen(!open)}>
			<img src={image} />
			<div className={styles.wrapperText}>
				<h4>{title}</h4>
				<div className={styles.whiteBg}>
					<p>{text}</p>
					<ul>
						<li>
							<p>
								<strong>Tipo de ola:</strong> {waveType}
							</p>
						</li>
						<li>
							<p>
								<strong>Nivel del spot:</strong> {spotLevel}
							</p>
						</li>
					</ul>
					<Link to={linkTo}>
						{linkText}
						<ReactSVG src="/img/arrow.svg" wrapper="span" />
					</Link>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Beach);
