import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { Link, navigate } from "gatsby-plugin-intl";
import Beach from "../components/Beach";
import SliderCustom from "../components/common/SliderCustom";
import { connect } from "react-redux";

const Playas = ({ s }) => {
	const beachesDetails = [
		{
			image: "/img/dummy-beach.jpg",
			title: "Los Locos",
			text:
				"La playa de los surfers por excelencia en Cantabria. Esta playa acantilada se encuentra en Suances. Anualmente celebran sel OA2 FuSSSion durante el mes de agosto.",
			waveType: "picos variables",
			spotLevel: "Surfistas y bodyboarders",
			linkTo: "/22222",
			linkText: "Ver tablas",
		},
		{
			image: "/img/dummy-beach.jpg",
			title: "Los Locos",
			text:
				"La playa de los surfers por excelencia en Cantabria. Esta playa acantilada se encuentra en Suances. Anualmente celebran sel OA2 FuSSSion durante el mes de agosto.",
			waveType: "picos variables",
			spotLevel: "Surfistas y bodyboarders",
			linkTo: "/22222",
			linkText: "Ver tablas",
		},
		{
			image: "/img/dummy-beach.jpg",
			title: "Los Locos",
			text:
				"La playa de los surfers por excelencia en Cantabria. Esta playa acantilada se encuentra en Suances. Anualmente celebran sel OA2 FuSSSion durante el mes de agosto.",
			waveType: "picos variables",
			spotLevel: "Surfistas y bodyboarders",
			linkTo: "/22222",
			linkText: "Ver tablas",
		},
		{
			image: "/img/dummy-beach.jpg",
			title: "Los Locos",
			text:
				"La playa de los surfers por excelencia en Cantabria. Esta playa acantilada se encuentra en Suances. Anualmente celebran sel OA2 FuSSSion durante el mes de agosto.",
			waveType: "picos variables",
			spotLevel: "Surfistas y bodyboarders",
			linkTo: "/22222",
			linkText: "Ver tablas",
		},
		{
			image: "/img/dummy-beach.jpg",
			title: "Los Locos",
			text:
				"La playa de los surfers por excelencia en Cantabria. Esta playa acantilada se encuentra en Suances. Anualmente celebran sel OA2 FuSSSion durante el mes de agosto.",
			waveType: "picos variables",
			spotLevel: "Surfistas y bodyboarders",
			linkTo: "/22222",
			linkText: "Ver tablas",
		},
		{
			image: "/img/dummy-beach.jpg",
			title: "Los Locos",
			text:
				"La playa de los surfers por excelencia en Cantabria. Esta playa acantilada se encuentra en Suances. Anualmente celebran sel OA2 FuSSSion durante el mes de agosto.",
			waveType: "picos variables",
			spotLevel: "Surfistas y bodyboarders",
			linkTo: "/22222",
			linkText: "Ver tablas",
		},
	];

	const cantabricoSliderDetails = [
		{
			id: 1946,
			imagenes: [{ url: "/img/dummy-board.jpg" }],
			title: "Tabla de Surf evolutiva Re-Boards",
			operacion: 3,
			precio_alquiler: "10€/hora",
			precio_venta: "240€",
			link: "/",
		},
		{
			id: 1946,
			imagenes: [{ url: "/img/dummy-board-02.jpg" }],
			title: "Bodyboard Nomad Cramsie Prodigy D12 42",
			operacion: 3,
			precio_alquiler: "10€/hora",
			precio_venta: "240€",
			link: "/",
		},
		{
			imagenes: [{ url: "/img/dummy-board.jpg" }],
			title: "Tabla de Surf evolutiva Re-Boards",
			operacion: 1,
			precio_alquiler: "10€/hora",
			precio_venta: "",
			link: "/",
		},
		{
			id: 1946,
			imagenes: [{ url: "/img/dummy-board-02.jpg" }],
			title: "Tabla de Surf evolutiva Re-Boards",
			operacion: 3,
			precio_alquiler: "10€/hora",
			precio_venta: "240€",
			link: "/",
		},
		{
			id: 1946,
			imagenes: [{ url: "/img/dummy-board.jpg" }],
			title: "Bodyboard Nomad Cramsie Prodigy D12 42",
			operacion: 3,
			precio_alquiler: "10€/hora",
			precio_venta: "240€",
			link: "/",
		},
		{
			id: 1946,
			imagenes: [{ url: "/img/dummy-board-02.jpg" }],
			title: "Tabla de Surf evolutiva Re-Boards",
			operacion: 3,
			precio_alquiler: "10€/hora",
			precio_venta: "240€",
			link: "/",
		},
		{
			id: 1946,
			imagenes: [{ url: "/img/dummy-board.jpg" }],
			title: "Tabla de Surf evolutiva Re-Boards",
			operacion: 3,
			precio_alquiler: "10€/hora",
			precio_venta: "240€",
			link: "/",
		},
	];

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="seas">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Cantábrico" />
					<main className="page">
						<div className="bg-img">
							<div className="wrapper-text">
								<h1 className="white-text prequel-font">CANTABRICO</h1>
								<Link to="/">Ver 180 tablas</Link>
							</div>
							<picture>
								<source srcSet="/img/bg/community-desktop.jpg" media="(min-width: 768px)" />
								<img srcSet="/img/bg/community.jpg" />
							</picture>
						</div>

						<div className="box text">
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
								labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
								laboris nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud
								exercitation ullamco laboris nisi
							</p>
						</div>

						{/* MAP */}
						<div className="box map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1480996.1612979926!2d-6.3486245690727845!3d43.53214576493167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd49e2085a5c32fd%3A0xa724cb5a2c67d6ad!2s33560%20Ribadesella%2C%20Asturias!5e0!3m2!1ses-419!2ses!4v1616671457389!5m2!1ses-419!2ses"
								width="600"
								height="450"
								style={{ border: 0, width: "100%" }}
								allowfullscreen=""
								loading="lazy"
							></iframe>
						</div>
						{/* MAP */}

						{/* BEACHES */}
						<div className="beaches">
							<div className="wrapper">
								<div className="wrapperText">
									<h3>Las playas del Norte</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut
										labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco
										laboris.
									</p>
								</div>
								<div class="beachesWrapper">
									{beachesDetails.map((beachesDetail) => (
										<Beach
											image={beachesDetail.image}
											title={beachesDetail.title}
											text={beachesDetail.text}
											waveType={beachesDetail.waveType}
											spotLevel={beachesDetail.spotLevel}
											linkTo={beachesDetail.linkTo}
											linkText={beachesDetail.linkText}
										/>
									))}
								</div>
							</div>
						</div>
						{/* BEACHES */}

						{/* SLIDER TABLAS CANTABRICO */}
						{cantabricoSliderDetails && (
							<SliderCustom
								title="Tablas en el Cantábrico"
								linkText="Ver tablas en el Cantábrico"
								linkUrl="/"
								items={cantabricoSliderDetails}
								typeContent="board"
							/>
						)}
						{/* SLIDER TABLAS CANTABRICO */}
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Playas);
